/*
Footer Component CSS
*/

.footer_Upper_Section {
    background: var(--prime-gm-color-primary);
}

a {
    text-decoration: none;
}

.footer_Upper_Section > div{
    display: flex;
    width: 80%;
    margin: auto;
    justify-content: space-between;
    padding: 60px 0 40px 0;
    color: #fff;
}

.footer_Upper_Section > div > div > div {
    margin-bottom: 15px;
}

.footer_Upper_Section > div > div > div > a {
    color: var(--prime-gm-text-color-OnPrimary);
    text-decoration: none;
}

.footer_Upper_Section > div > div > div > a:hover {
    color: var(--prime-gm-color-secondary);
}

.visible-phone {
    visibility: hidden;
}

.footer_Lower_Section {
    padding: 15px 0;
    color: #175C2E;
    background: #96C947;
    text-align: center;
}

.footer_Upper_Section_Col1 > div {
    margin: 20px 0 0 20px;
}

.footer_Upper_Section_Col1 > div > h4 {
    margin: 20px 0 10px 0;
}

.footer_Upper_Section_Col1 > div > div {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.footer_Upper_Section_Col1 > div > div > input {
    color: #fff;
    border: none;
    background-color: var(--prime-gm-color-primary);
    outline: none;
}

.footer_Upper_Section_Col1 > div > div > input::placeholder {
    color: var(--prime-gm-color-secondary);
}

.footer_Upper_Section_Col1 > div > hr {
    border: 1px solid var(--prime-gm-color-secondary);
}

.footer_Upper_Section_Col2 > h3, .footer_Upper_Section_Col3 > h3, .footer_Upper_Section_Col4 > h3 {
    margin-bottom: 30px;
}

.footer_Upper_Section_Col4 > div {
    display: flex;
    margin: 10px 0px;
}

.footer_Upper_Section_Col4 > div > p {
    margin-left: 10px;
}

.footer_Upper_Section_Col4_Socials {
    padding-top: 10px;
}

.footer_Upper_Section_Col4_Socials > div {
    display: flex;
    height: 30px;
    width: 30px;
    
    align-items: center;
    margin-right: 10px;
    padding: 4px 0 0 4px;
    border-radius: 5px;
    background-color: var(--prime-gm-color-secondary);
}

.footer_Upper_Section_Col4_Socials > div > a {
    color: var(--prime-gm-color-primary);
    text-decoration: none;
}

.footer_Upper_Section_Col4_Socials > div > a:hover {
    color: var(--prime-gm-text-color-OnSecondary);
}

/*
Footer Page Responsiveness Section for Mobile Phones and Tablet
*/

@media screen and (max-width: 920px) {
    .footer_Upper_Section > div {
        display: flex;
        flex-direction: column;
    }

    .footer_Upper_Section_Col1 > div {
        display: flex;
        flex-direction: column;
    }

    .footer_Upper_Section_Col1 > div {
        margin: 20px 0 0 0px;
    }

    .footer_Upper_Section_Col1 > div > p, .footer_Upper_Section_Col1 > div > h4, .footer_Upper_Section_Col1 > a {
        display: flex;
        justify-content: center;
    }

    .footer_Upper_Section > div > div > div {
        display: flex;
        justify-content: center;
    }

    .footer_Upper_Section > div > div > h3 {
        display: flex;
        justify-content: center;
    }

    .visible-phone {
        visibility: visible;
        border: 1px solid var(--prime-gm-color-secondary);
        margin: 15px 0px;
    }
}



