.QuotePoliciesItem {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 200px;
    border: 3px solid #ECECEC;
    border-radius: 15px;
    padding: 10px 10px;
    background: white;
}

.QuotePoliciesItem > img {
    width: 50px;
    margin-bottom: 20px;
}

.QuotePoliciesItem > h4 {
    margin-bottom: 15px;
}

.QuotePoliciesItem > p {
    margin-bottom: 0px;
}

.QuotePoliciesItem > hr {
    display: flex;
    align-items: flex-end;
    height: 5px;
    width: 50px;
    background-color: var(--prime-gm-color-secondary);
    border-radius: 10px;
    margin-top: 15px;
}

@media screen and (max-width: 600px) {
    .QuotePoliciesItem {
        display: flex;
        flex-direction: column;
        width: 230px;
        height: 200px;
        border: 3px solid #ECECEC;
        border-radius: 15px;
        padding: 10px 10px;
        background: white;
        margin: 0px 10px
    }
}