
/*
.Products_Section_Card {
    display: flex;
    align-items: flex-end;
    width: 300px;
    height: 300px;
    margin-bottom: 40px;
    border-radius: 20px;
    box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.2); 
} */

.Products_Section_Card {
    display: flex;
    flex-direction: column;
    justify-content: left;
    width: 100%;
    height: 370px;
    background-color: #2462D2;
    background-position: center;
    background-size: cover;
    background-repeat: cover no-repeat;
    color: #fff;
    margin-bottom: 20px;
    border-radius: 5px;
}

/* .Products_Section_Card > span {
    padding: 40px;
    width: 100%;
    height: 100%;
    background: linear-gradient(120deg, rgb(41, 61, 72) 55%, rgba(150,201,71,0.21808473389355745) 100%);
} */

.Products_Section_Card > span {
    padding: 40px;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    background: linear-gradient(60deg, rgb(108, 168, 17, 0.98) 55%, rgba(150,201,71,0.21808473389355745) 100%);
}

.ProtuctIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 80px;
    background-color: #185C2E;
    border-radius: 5px;
}

.ProductContent {
    margin: 25px 0px;
}

.ProductContent > h2 {
    margin-bottom: 25px;
}

.Products_Section_Card_Highlight {
    display: flex;
    color: #fff;
    align-items: center;
    justify-content: center;
    width: 150px;
    height: 30px;
    margin-left: 20px;
    margin-bottom: 20px;
    background: var(--prime-gm-GradientColor);
    text-align: center;
}

.text-ellipsis{
    text-overflow:ellipsis;
    overflow:hidden;
    
    display: -webkit-box !important;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    white-space: normal;
  }