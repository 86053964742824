.chooseUs {
    display: flex;
    width: 100%;
    min-height: 300px;
    margin-top: 50px;
    background-image: url(../media/img/anniversaryBackground.png);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

.chooseUsContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 80%;
    min-height: 400px;
    margin: 0px auto 0px auto;
    padding: 0px 0px 0px 0px;
    justify-content: space-between;
}

.chooseUsContainerLeft {
    display: flex;
    padding: 30px 0px 0px 0px;
    flex-direction: column;
    width: 45%;
}

.chooseUsContainerRight {
    display: flex;
    flex-direction: column;
    width: 50%;
}

.chooseUsContainerTop, .chooseUsContainerBottom {
    display: flex;
    justify-content: space-between;
    width: 100%;
    min-height: 150px;
    margin-bottom: 30px;
}

.chooseUsContainerTop > div, .chooseUsContainerBottom > div {
    width: 45%;
}

.chooseUsContainerTop > div > div, .chooseUsContainerBottom > div > div {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;
}


@media screen and (max-width: 1024px) {
    .chooseUsContainer {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 80%;
        min-height: 400px;
        margin: 0px auto 0px auto;
        padding: 0px 0px 0px 0px;
        justify-content: space-between;
    }

    .chooseUsContainerLeft {
        display: flex;
        padding: 30px 0px 0px 0px;
        flex-direction: column;
        width: 45%;
    }
    
    .chooseUsContainerRight {
        display: flex;
        flex-direction: column;
        width: 50%;
    }

    .chooseUsContainerTop, .chooseUsContainerBottom {
        display: flex;
        justify-content: space-between;
        width: 100%;
        min-height: 150px;
        margin-bottom: 30px;
    }
    
    .chooseUsContainerTop > div, .chooseUsContainerBottom > div {
        width: 45%;
    }
}


@media screen and (max-width: 768px) {
    .chooseUsContainer {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        width: 80%;
        min-height: 400px;
        margin: 0px auto 0px auto;
        padding: 0px 0px 0px 0px;
        justify-content: space-between;
    }

    .chooseUsContainerLeft {
        display: flex;
        padding: 30px 0px 0px 0px;
        flex-direction: column;
        width: 100%;
        margin-bottom: 80px;
    }

    .chooseUsContainerRight {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .chooseUsContainerTop, .chooseUsContainerBottom {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        width: 100%;
        min-height: 150px;
        margin-bottom: 30px;
    }
    
    .chooseUsContainerTop > div, .chooseUsContainerBottom > div {
        width: 100%;
    }
}
