.ProductsItem_Single {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 200px;
    height: 40px;
    background-color: #F4FAF6;
    border: 1px solid var(--prime-gm-color-primary);
    border-radius: 10px;
    padding: 0px 20px;
    margin: 10px 0;
    cursor: pointer;
}    

.ProductsItem_Single > p > a {    
    color: var(--prime-gm-color-primary);
    text-decoration: none;
}

@media screen and (max-width: 1024px) {
    .ProductsItem_Single {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 200px;
        height: 40px;
        background-color: #F4FAF6;
        border: 1px solid var(--prime-gm-color-primary);
        border-radius: 10px;
        padding: 0px 20px;
        margin: 10px 0;
        cursor: pointer;
    }   
}

@media screen and (max-width: 768px) {
    .ProductsItem_Single {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 40px;
        background-color: #F4FAF6;
        border: 1px solid var(--prime-gm-color-primary);
        border-radius: 10px;
        padding: 0px 20px;
        margin: 10px 0;
        cursor: pointer;
    }   
}