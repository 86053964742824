.Products_Highlight {
    display: flex;
    align-items: center;
    justify-content: right;
    text-align: right;
    padding: 0 10%;
    color: #fff;
    min-height: 350px;
    background: url(../media/img/products.png) no-repeat;
    background-size: cover;
}

.Products_Highlight > div > h1 {
    margin-bottom: 20px;
    color: #FEFE0C;
    font-size: 50px;
    font-weight: 600;
}

.Products_Highlight > div > p {
    margin-bottom: 20px;
    font-size: 25px;
}

.Products_Page_Highlight > p{
    width: 80%;
    margin: 40px auto;
    text-align: center;
}

.Products_Page_Others {
    width: 80%;
    margin: 40px auto;
}

.Products_Page_Policies {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 40px;
}

.Products_Page_Selected {
    background-color: #F4FAF6;
}


@media screen and (max-width: 1024px) {
    .Products_Highlight {
        display: flex;
        align-items: center;
        justify-content: right;
        text-align: right;
        padding: 0 10%;
        color: #fff;
        min-height: 350px;
        background: url(../media/img/products.png) no-repeat;
        background-size: cover;
    }

    .Products_Highlight > div > h1 {
        margin-bottom: 20px;
        color: #FEFE0C;
        font-size: 50px;
        font-weight: 600;
    }

    .Products_Page_Policies {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 40px;
    }
}

@media screen and (max-width: 768px) {
    .Products_Highlight {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding: 0 10%;
        color: #fff;
        min-height: 350px;
        background: url(../media/img/products.png) no-repeat;
        background-size: cover;
    }

    .Products_Highlight > div {
        display: flex;
        justify-content: center;
        margin-bottom: 20px;
        color: #FEFE0C;
        font-size: 50px;
        text-align: center;
        font-weight: 600;
    }

    .Products_Page_Policies {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 40px;
    }

    .Products_Page_Policies > div {
        width: 100%;
    }
}