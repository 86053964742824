.productsPage {
    width: 100%;
}

.MuiButtonBase-root {
    display: flex !important;
    padding: 5px 20px !important;
}

.productsPageItem {
    width: 80%;
    margin: 0px auto;
}

.productsPageExplanation {
    width: 80%;
    margin: 50px auto;
}

.productsPageExplanation > p {
    margin: 20px 0px;
}

.productsPageBenefitsList {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
}

.productsPageBenefitsList > div {
    display: flex;
    align-items: center;
    width: 32%;
    margin-bottom: 20px;
}

.productsPageMoreDownload {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 80%;
    margin: 0px auto 50px auto;
}

.productsPageMoreDownloadLeft {
    width: 25%;
    max-height: 530px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    /* box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px; */
    border-radius: 10px 0px 0px 10px;
}

.productsPageMoreDownloadLeftTop {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 60px;
    padding: 0px 15px;
    color: #fff;
    border-radius: 10px 0px 0px 0px;
    background-color: var(--prime-gm-color-primary);
}

.productsPageMoreDownloadMiddleTop {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 60px;
    padding: 0px 15px;
    color: #fff;
    border-radius: 0px 0px 0px 0px;
    background-color: var(--prime-gm-color-primary);
}

.productsPageMoreDownloadRightTop {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 60px;
    padding: 0px 15px;
    color: #fff;
    border-radius: 0px 10px 0px 0px;
    background-color: var(--prime-gm-color-primary);
}

.productsPageMoreDownloadRightMiddle {
    padding: 15px;
}

.productsPageMoreDownloadLeftMiddle {
    padding: 20px;
}

.productsPageMoreDownloadLeftMiddleDownloadFile {
    display: flex;
    height: 50px;
    width: 100%;
    margin-bottom: 15px;
    cursor: pointer;
}

.productsPageMoreDownloadLeftMiddleDownloadFileIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 100%;
    border-radius: 5px 0px 0px 5px;
    background-color: #175C2E;
}

.productsPageMoreDownloadLeftMiddleDownloadFileName {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: left;
    padding: 0px 15px;
    border: 1px solid #d5d5d5;
    border-radius: 0px 5px 5px 0px;
}

.productsPageMoreDownloadLeftBottom {
    height: 300px;
    background-color: #175C2E;
    border-radius: 0px 0px 0px 10px;
}

.productsPageMoreDownloadCenter {
    width: 45%;
    background-color: aliceblue;
}

.productsPageMoreDownloadRight {
    width: 25%;
    max-height: 530px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px; 
    /* box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px; */
    border-radius: 10px 10px 10px 0px;
}



@media screen and (max-width: 1024px) {
    .productsPage {
        width: 100%;
    }

    .productsPageBenefitsList > div {
        display: flex;
        align-items: center;
        width: 32%;
        margin-bottom: 20px;
    }

    .productsPageMoreDownload {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 80%;
        margin: 0px auto;
    }
    
    .productsPageMoreDownloadLeft {
        width: 25%;
    }

    .productsPageMoreDownloadRight {
        width: 25%;
        max-height: 530px;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px; 
        /* box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px; */
        border-radius: 0px 10px 10px 0px;
    }

    .productsPageMoreDownloadRightTop {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 60px;
        padding: 0px 15px;
        color: #fff;
        border-radius: 0px 10px 0px 0px;
        background-color: var(--prime-gm-color-primary);
    }

    .productsPageMoreDownloadCenter {
        width: 45%;
        background-color: aliceblue;
    }
}

@media screen and (max-width: 768px) {
    .productsPage {
        width: 100%;
    }

    .productsPageBenefitsList > div {
        display: flex;
        align-items: center;
        width: 100%;
        margin-bottom: 20px;
    }

    .productsPageMoreDownload {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 80%;
        margin: 50px auto;
    }
    
    .productsPageMoreDownloadLeft {
        width: 100%;
    }

    .productsPageMoreDownloadLeftTop {
        display: flex;
        align-items: center;
        width: 100%;
        height: 60px;
        padding: 0px 15px;
        color: #fff;
        border-radius: 10px 10px 0px 0px;
        background-color: var(--prime-gm-color-primary);
    }

    .productsPageMoreDownloadLeftBottom {
        height: 300px;
        background-color: #175C2E;
        border-radius: 0px 0px 0px 0px;
    }

    .productsPageMoreDownloadRight {
        width: 100%;
        max-height: 530px;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px; 
        /* box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px; */
        border-radius: 10px 10px 10px 10px;
    }

    .productsPageMoreDownloadRightTop {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 60px;
        padding: 0px 15px;
        color: #fff;
        border-radius: 0px 0px 0px 0px;
        background-color: var(--prime-gm-color-primary);
    }

    .productsPageMoreDownloadCenter {
        width: 100%;
        margin: 30px 0px;
        background-color: aliceblue;
    }
}