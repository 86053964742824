.Contact_Us_Highlight {
    display: flex;
    align-items: center;
    justify-content: end;
    text-align: right;
    padding: 0 10%;
    color: #fff;
    min-height: 350px;
    background: url(../media/img/About\ Us\ -\ Caption.png) no-repeat;
    background-size: cover;
}

.Contact_Us_Highlight > div {    
    width: 100%;
}

.Contact_Us_Highlight > div > h1 {
    margin-bottom: 20px;
    color: #FEFE0C;
    font-size: 50px;
    font-weight: 600;
}

.Contact_Us_Highlight > div > p {
    margin-bottom: 20px;
    font-size: 25px;
}

@media screen and (max-width: 1024px) {
    .Contact_Us_Highlight {
        display: flex;
        align-items: center;
        justify-content: end;
        text-align: right;
        padding: 0 10%;
        color: #fff;
        min-height: 350px;
        background: url(../media/img/About\ Us\ -\ Caption.png) no-repeat;
        background-size: cover;
    }
}

@media screen and (max-width: 768px) {
    .Contact_Us_Highlight {
        display: flex;
        align-items: center;
        justify-content: end;
        text-align: center;
        padding: 0 10%;
        color: #fff;
        min-height: 350px;
        background: url(../media/img/About\ Us\ -\ Caption.png) no-repeat;
        background-size: cover;
    }
}

