body {
  --prime-gm-color-primary: #175C2E;
  --prime-gm-color-secondary: #96C947;
  --prime-gm-color-secondary-filler: #FBCE4D;
  --prime-gm-color-lime: #F0F8F3;
  --prime-gm-GradientColor: linear-gradient(90deg, rgba(150,201,71,1) 0%, rgba(23,92,46,1) 100%);
  --prime-gm-text-color-OnPrimary: #fff;
  --prime-gm-text-color-OnSecondary: #fff;
  --prime-gm-text-color-OnSecondaryContrast: #175C2E;
}

* {
  font-family: 'Maven Pro', 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
}

/* .doNotShowOnDesktop {
  display: none;
}

.doNotShowOnMobile {
  display: contents;
}


@media screen and (max-width: 768px) {
  .doNotShowOnMobile {
    display: none;
  }

  .doNotShowOnDesktop {
    display: contents;
  }
} */