.otherBranchItem {
    width: 100%;
}

.OtherBranchesItem_Branch {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 40px;
    margin-bottom: 15px;
    border-radius: 8px;
    background-color: var(--prime-gm-color-secondary);
}

.OtherBranchesItem_Tel {
    min-height: 60px;
}

.OtherBranchesItem_Tel > p {    
    font-size: 13px;
    margin-top: 5px;
    margin-left: 10px;
}

@media screen and (max-width: 1024px) {
    .otherBranchItem {
        width: 23%;
    }
    
    .OtherBranchesItem_Branch {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 40px;
        margin-top: 15px;
        border-radius: 8px;
        background-color: var(--prime-gm-color-secondary);
    }
    
    .OtherBranchesItem_Tel {
        min-height: 60px;
    }
}

@media screen and (max-width: 768px) {
    .otherBranchItem {
        width: 100%;
    }
    
    .OtherBranchesItem_Branch {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 40px;
        margin-top: 15px;
        border-radius: 8px;
        background-color: var(--prime-gm-color-secondary);
    }
    
    .OtherBranchesItem_Tel {
        min-height: 40px;
        margin-top: 30px;
    }
}