.Forms_Heading {
    display: flex;
    width: 80%;
    margin: 20px auto;
}

.Forms_Heading_Main {
    display: flex;
    flex: 0.25;
    width: 100%;
    height: 50px;
    background-color: var(--prime-gm-color-primary);
    justify-content: center;
    align-items: center;
}

.Forms_Heading_Main > h2 {
    display: flex;
    align-items: center;
    color: #fff;
}

.Forms_Heading_Thread {
    display: flex;
    flex: 0.75;
    width: 100%;
    align-items: center;
}

.Forms_Heading_Thread_Left {
    visibility: hidden;
}

.Forms_Heading_Thread > hr, .Forms_Heading_Thread_Left > hr {
    display: flex;
    background-color: var(--prime-gm-color-primary);
    width: 100%;
    height: 2px;
    align-items: center;
    border: none;
}

@media screen and (max-width: 920px) {
    .Forms_Heading_Main {
        display: flex;
        justify-content: center;
        box-sizing: border-box;
        padding: 0;
        flex: 0.7;
        width: 100%;
        background-color: var(--prime-gm-color-primary);
        text-align: center;
    }

    .Forms_Heading_Thread {
        display: flex;
        flex: 0.15;
        width: 100%;
        align-items: center;
    }

    .Forms_Heading_Thread_Left {        
        display: flex;
        flex: 0.15;
        visibility: visible;
        width: 100%;
        align-items: center;
    }

}