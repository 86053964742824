.ProductHighlight {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 80%;
    margin: 50px auto;
    padding: 50px 0px 20px 0px;
}

.ProductHighlight_Right > h1 {
    font-size: 40px;
}

.ProductHighlight_Right > p {
    margin: 35px 0px;
    font-size: 24px;
}

.ProductHighlight_Right_Bullet > div {
    display: flex;
    align-items: center;
    margin: 10px 0px;
}

.ProductHighlight_Right_Bullet > div > p {
    font-size: 20px;
}

.ProductHighlight_Right_Bullet_Icon {
    width: 15px;
    height: 15px;
    background-color: var(--prime-gm-color-secondary);
    border-radius: 10px;
    margin-right: 15px;
}

@media screen and (max-width: 1024px) {
    .ProductHighlight {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 80%;
        margin: 50px auto;
        padding: 50px 0px 20px 0px;
    }

    .ProductHighlight_Right > h1 {
        font-size: 40px;
    }
}

@media screen and (max-width: 768px) {
    .ProductHighlight {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        width: 80%;
        margin: 50px auto;
        padding: 50px 0px 20px 0px;
    }

    .ProductHighlight_Left > img {
        width: 100%;
        margin-bottom: 30px;
    }

    .ProductHighlight_Right > h1 {
        font-size: 24px;
    }
}