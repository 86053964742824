.MissionAndVision_top {
    width: 100%;    
    padding: 0 10%;
    min-height: 350px;
    background: url(../media/img/AboutUsPrime.png) no-repeat center / cover;
}

.MissionAndVision_top > h1 {
    display: flex;
    justify-content: right;
    align-items: right;
    padding-top: 60px;
    font-size: 50px;
    color: #FEFE0C;
}

.MissionAndVision_top > p {
    display: flex;
    justify-content: right;
    text-align: right;
    color: white;
    margin-bottom: 20px;
    font-size: 25px;
}

.MissionAndVision_down {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 80%;    
    height: 200px;
    margin: -75px auto 50px auto;
    border-radius: 15px;
    background-color: var(--prime-gm-color-lime);
    border: 2px solid var(--prime-gm-color-secondary);
}

.MissionAndVision_down_Vision, .MissionAndVision_down_Mission {
    border: 1px dashed black;
    border-radius: 10px;
    border-color: #7A7D7B;
    width: 45%;
    min-height: 130px;
    padding: 20px 15px;
}

.MissionAndVision_down_Vision > h2, .MissionAndVision_down_Mission > h2 {
    margin-bottom: 20px;
}


@media screen and (max-width: 768px) {
    .MissionAndVision_top {
        min-height: 250px;
    }

    .MissionAndVision_down {
        display: flex;
        flex-direction: column;
        overflow-x: scroll;
        padding-left: 0px;
        min-height: 450px;
    }

    .MissionAndVision_down_Vision, .MissionAndVision_down_Mission {
        border: 1px dashed black;
        border-radius: 10px;
        border-color: #7A7D7B;
        width: 90%;
        margin: 0px auto;
        min-height: 130px;
        padding: 20px 15px;
    }

    .MissionAndVision_down::-webkit-scrollbar {
        display: none;
    }

    .MissionAndVision_down {
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
      }

      .MissionAndVision_top > h1 {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        padding-top: 50px;
        font-size: 30px;
    }

    .MissionAndVision_top > p {
        display: flex;
        justify-content: center;
        text-align: center;
        color: white;
        margin-bottom: 20px;
        font-size: 18px;
    }
}
