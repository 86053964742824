.AboutContent {
    width: 100%;
}

.AboutContent > div {
    display: flex;
    width: 80%;
    margin: 0px auto;
    align-items: center;
}

.AboutContent > div > div > p {
    margin-top: 20px;
}

.AboutContent_Image {
    margin-right: 40px;
}



@media screen and (max-width: 600px) {
    .AboutContent > div {
        display: flex;
        flex-direction: column;
        width: 80%;
        margin: 0px auto;
        align-items: center;
    }

    .AboutContent_Image {
        margin-right: 0px;
        margin-bottom: 20px;
    }
}