.QuotePoliciesAction {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80%;
    margin: 0px auto 50px auto;
    height: 65px;
    border: 3px solid var(--prime-gm-color-secondary);
    border-radius: 70px;
    padding: 0 8px;
}

.QuotePoliciesAction > div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 180px;
    height: 50px;
    background-color: var(--prime-gm-color-secondary);
    color: #fff;
    border-radius: 60px;
    cursor: pointer;
}

.QuotePoliciesAction > div > a {
    text-decoration: none;
    color: white;
}

.modalViewQuote {
    width: 70vw;
    height: 70vh; 
}

.modalViewQuoteContent {
    overflow-y: scroll; 
    height: 55vh;
    width: 100%;
}

@media screen and (max-width: 768px) {
    .QuotePoliciesAction {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 80%;
        margin: 0px auto 50px auto;
        height: 220px;
        border: 3px solid var(--prime-gm-color-secondary);
        border-radius: 20px;
        padding: 20px 8px;
    }
    
    .QuotePoliciesAction > div {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 180px;
        height: 50px;
        background-color: var(--prime-gm-color-secondary);
        color: #fff;
        border-radius: 60px;
        cursor: pointer;
    }
    
    .QuotePoliciesAction > div > a {
        text-decoration: none;
        color: white;
    }

    .modalViewQuote {
        width: 100vw;
        height: 95vh; 
    }

    .modalViewQuoteContent {
        overflow-y: scroll; 
        height: 80vh;
        width: 100%;
    }
}