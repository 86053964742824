.GetInTouchForms {
    display: flex;
    width: 600px;
    box-sizing: border-box;
}

form {
    display: flex;
    flex-wrap: wrap;
    margin-top: 40px;
}

.GetInTouchForms_InputBox_Query_all {
    display: flex;
    width: 500px;
    flex-wrap: wrap;
}

.GetInTouchForms_InputBox_Query > input {
    display: flex;
    flex-direction: row;
    height: 40px;
    width: 200px;
    border: 1.5px solid var(--prime-gm-color-secondary);
    margin-bottom: 15px;
    margin-right: 15px;
    padding: 0px 10px;
}

textarea {
    height: 80px;
    width: 415px;
    border: 1.5px solid var(--prime-gm-color-secondary);
    margin-bottom: 15px;
    padding: 10px 10px;
}

input[type=submit] {
    cursor: pointer;
    width: 415px;
    height: 40px;
    background: var(--prime-gm-color-secondary);
    color: var(--prime-gm-color-primary);
    font-weight: bold;
    font-size: 20px;
    border: none;
}

.GetInTouchFomrs_Agree {
    display: flex;
    align-items: center;
    justify-content:space-between;
    width: 415px;
    margin-bottom: 15px;
}

input[type=checkbox] {
    background-color: var(--prime-gm-color-secondary);
}

.GetInTouchFomrs_Agree > p {
    font-size: 14px;
}

@media screen and (max-width: 1024px) {
    .GetInTouchForms {
        display: flex;
        width: 600px;
        box-sizing: border-box;
    }

    .GetInTouchForms_InputBox_Query > input {
        display: flex;
        flex-direction: row;
        height: 40px;
        width: 200px;
        border: 1.5px solid var(--prime-gm-color-secondary);
        margin-bottom: 15px;
        margin-right: 15px;
        padding: 0px 10px;
    }

    .GetInTouchForms_InputBox_Query_all {
        display: flex;
        width: 500px;
        flex-wrap: wrap;
    }

    textarea {
        height: 80px;
        width: 415px;
        border: 1.5px solid var(--prime-gm-color-secondary);
        margin-bottom: 15px;
        padding: 10px 10px;
    }

    input[type=submit] {
        cursor: pointer;
        width: 415px;
        height: 40px;
        background: var(--prime-gm-color-secondary);
        color: var(--prime-gm-color-primary);
        font-weight: bold;
        font-size: 20px;
        border: none;
    }

    .GetInTouchFomrs_Agree {
        display: flex;
        align-items: center;
        justify-content:space-between;
        width: 415px;
        margin-bottom: 15px;
    }
}

@media screen and (max-width: 768px) {
    .GetInTouchForms {
        display: flex;
        width: 100%;
        margin: 30px auto;
        box-sizing: border-box;
    }

    .GetInTouchForms_InputBox_Query > input {
        display: flex;
        flex-direction: row;
        height: 40px;
        width: 100%;
        border: 1.5px solid var(--prime-gm-color-secondary);
        margin-bottom: 15px;
        margin-right: 15px;
        padding: 0px 10px;
    }

    .GetInTouchForms_InputBox_Query_all {
        display: flex;
        width: 100%;
        flex-wrap: wrap;
    }

    .GetInTouchForms_InputBox_Query {
        width: 100%;
    }

    .GetInTouchForms_InputBox {
        width: 100%;
    }

    textarea {
        height: 80px;
        width: 100%;
        border: 1.5px solid var(--prime-gm-color-secondary);
        margin-bottom: 15px;
        padding: 10px 10px;
    }

    input[type=submit] {
        cursor: pointer;
        width: 100%;
        height: 40px;
        background: var(--prime-gm-color-secondary);
        color: var(--prime-gm-color-primary);
        font-weight: bold;
        font-size: 20px;
        border: none;
    }

    .GetInTouchFomrs_Agree {
        display: flex;
        align-items: center;
        justify-content:space-between;
        width: 100%;
        margin-bottom: 15px;
    }

    .GetInTouchFomrs_Agree > input {
        margin-right: 20px;
    }
}