.MainContactInfo_Section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 80%;
    margin: 80px auto;
    padding: 0px;
}

.MainContactInfo_Section > div {
    display: flex;
    width: 30%;
}

@media screen and (max-width: 1024px) {
    .MainContactInfo_Section {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 80%;
        margin: 80px auto;
        padding: 0px;
    }
    
    .MainContactInfo_Section > div {
        display: flex;
        width: 30%;
    }
}

@media screen and (max-width: 768px) {
    .MainContactInfo_Section {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        width: 80%;
        margin: 50px auto;
        padding: 0px;
    }
    
    .MainContactInfo_Section > div {
        display: flex;
        width: 100%;
    }
}