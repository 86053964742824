.insuranceProduct {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 80%;
    margin: 50px 0px;
}

.insuranceProductInfo {
    width: 60%;
}

.insuranceProductInfo > p {
    margin: 30px 0px;
}

.insuranceProductImg {
    display: flex;
    justify-content:left;
    width: 30%;
}

.insuranceProductImg > img {
    height: 500px;
}

@media screen and (max-width: 1024px) {
    .insuranceProduct {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 80%;
        margin: 50px 0px;
    }
    
    .insuranceProductInfo {
        width: 60%;
    }
    
    .insuranceProductInfo > p {
        margin: 30px 0px;
    }
    
    .insuranceProductImg {
        display: flex;
        justify-content:left;
        width: 30%;
    }
    
    .insuranceProductImg > img {
        height: 300px;
    }
}


@media screen and (max-width: 768px) {
    .insuranceProduct {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        width: 80%;
        margin: 50px 0px;
    }
    
    .insuranceProductInfo {
        width: 100%;
    }
    
    .insuranceProductInfo > p {
        margin: 30px 0px;
        width: 100%;
    }
    
    .insuranceProductImg {
        display: flex;
        justify-content:left;
        width: 100%;
    }
    
    .insuranceProductImg > img {
        height: 300px;
        margin: 50px 0px 0px 0px;
    }
}
