.bestProviderItem {
    display: flex; 
    flex-direction: column; 
    width : 23%;
    margin-bottom: 0px;
}

@media screen and (max-width: 1024px) { 
    .bestProviderItem {
        display: flex; 
        flex-direction: column; 
        width : 100%;
        margin-bottom: 20px;
    }
}

@media screen and (max-width: 768px) {
    .bestProviderItem {
        display: flex; 
        flex-direction: column; 
        width : 100%;
        margin-bottom: 30px;
    }
}