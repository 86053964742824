.anniversary {
    display: flex;
    width: 100%;
    min-height: 300px;
    margin-bottom: 80px;
    background-image: url(../media/img/anniversaryBackground.png);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

.anniversaryContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 80%;
    min-height: 450px;
    margin: 0px auto 0px auto;
    padding: 0px 0px 0px 0px;
    justify-content: space-between;
}

.anniversaryContainerLeft {
    display: flex;
    padding: 100px 0px 0px 0px;
    flex-direction: column;
    width: 40%;
}

.anniversaryContainerRight {
    display: flex;
    position: relative;
    flex-direction: row;
    width: 58%;
}

.anniversaryContainerRightTrophy {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    z-index: 99;
}

.anniversaryContainerRightCarousel {
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
    background-color: #11823B;
    margin-left: 130px;
    border-radius: 10px;
    /*margin-top: 10px; */
    background-image: url(../media/img/anniversaryPic.jpg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover; 
}

.anniversaryContainerRightCarousel > span {
    display: flex;
    flex-direction: column;
    text-align: left;
    color: #fff;
    padding: 40px;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    /* background: linear-gradient(60deg, rgb(108, 168, 17, 0.98) 15%, rgba(150,201,71,0.21808473389355745) 100%); */
    background: linear-gradient(0deg, rgb(108, 168, 17, 0.98) 10%, rgba(150,201,71,0.21808473389355745) 60%);
}

@media screen and (max-width: 1024px) {

}

@media screen and (max-width: 768px) {
    .anniversaryContainer {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        width: 80%;
        min-height: 300px;
        margin: 0px auto 0px auto;
        padding: 0px 0px 0px 0px;
        justify-content: space-between;
    }

    .anniversaryContainerLeft {
        display: flex;
        padding: 10px 0px 0px 0px;
        flex-direction: column;
        width: 100%;
        margin-bottom: 100px;
    }
    
    .anniversaryContainerRight {
        display: flex;
        position: relative;
        flex-direction: row;
        width: 65%;
    }

    .reduceFont {
        font-size: 18px;
        font-weight: 700;
    }
}