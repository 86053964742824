.About_Branches {
    margin: 50px 0px 0px 0px;
    padding-top: 50px;
    background-color: #ECECEC;
}

.About_Branches > h1 {
    width: 80%;
    margin: 0px auto 30px auto;
}

.About_Branches > div {
    display: flex;
    justify-content: space-between;
    width: 80%;
    margin: 0 auto;
    flex-wrap: wrap;
}

.About_Branches > div > div {
    display: flex;
    width: 23%;    
    margin-bottom: 50px;
}


@media screen and (max-width: 600px) {
    .About_Branches > div > div {
        display: flex;
        flex-direction: column;
        width: 100%;    
        margin-bottom: 30px;
        text-align: center;
    }

    .About_Branches > h1 {
        width: 80%;
        margin: 0px auto 30px auto;
        text-align: center;
    }
}