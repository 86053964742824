.Management {
    width: 80%;
    margin: 50px auto;
}

.Management_Top > h1 {
    margin-bottom: 30px;
}

.Management_Down {
    display: flex;
    justify-content:flex-start;
    width: 100%;
    flex-wrap: wrap;
}

@media screen and (max-width: 1024px) { 

}

@media screen and (max-width: 768px) { 
    .Management_Down {
        display: flex;
        width: 100%;
        justify-content: space-between;
        overflow-x: scroll;
    }
}