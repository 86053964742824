.FormsDonwloadLink {
    width: 31%;
    height: 50px;
    border: 1.5px solid var(--prime-gm-color-primary);
    border-radius: 10px;
    margin: 15px 0px;
    padding: 0px 15px;
    cursor: pointer;
}

.FormsDonwloadLink > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.FormsDonwloadLink > div > h2 {
    font-weight: 500;
    color: var(--prime-gm-color-primary);
}

@media screen and (max-width: 1024px) {
    .FormsDonwloadLink {
        width: 31%;
        height: 50px;
        border: 1.5px solid var(--prime-gm-color-primary);
        border-radius: 10px;
        margin: 15px 0px;
        padding: 0px 15px;
        cursor: pointer;
    }
}

@media screen and (max-width: 768px) {
    .FormsDonwloadLink {
        width: 100%;
        height: 50px;
        border: 1.5px solid var(--prime-gm-color-primary);
        border-radius: 10px;
        margin: 15px 0px;
        padding: 0px 15px;
        cursor: pointer;
    }
}