.FirstChoice {
    display: block;
    margin-top: -35px;
}

.First_Choice_Highlight {
    display: flex;
    width: 100%;
    min-height: 70px;
    margin-bottom: 50px;
}

.First_Choice_Highlight_Main {
    display: flex;
    flex: 0.3;
    width: 100%;
    background-color: var(--prime-gm-color-primary);
    justify-content: right;
    padding-right: 21px;
}

.First_Choice_Highlight_Main > h1 {
    display: flex;
    align-items: center;
    color: #fff;
}

/*
.First_Choice_Highlight_Thread > div {
    display: flex;
    height: 35px;
    width: 100%;;
    background-color: var(--prime-gm-color-secondary-filler);;
} */

.First_Choice_Highlight_Thread {
    display: flex;
    flex: 0.7;
    width: 100%;
    align-items: center;
}

.First_Choice_Highlight_Thread_Left {
    visibility: hidden;
}

.First_Choice_Highlight_Thread > hr, .First_Choice_Highlight_Thread_Left > hr {
    display: flex;
    background-color: var(--prime-gm-color-primary);
    width: 100%;
    height: 3px;
    align-items: center;
    border: none;
}

@media screen and (max-width: 920px) {
    .First_Choice_Highlight_Main {
        display: flex;
        justify-content: center;
        box-sizing: border-box;
        padding: 0;
        flex: 0.7;
        width: 100%;
        background-color: var(--prime-gm-color-primary);
        text-align: center;
    }

    .First_Choice_Highlight_Thread {
        display: flex;
        flex: 0.15;
        width: 100%;
        align-items: center;
    }

    .First_Choice_Highlight_Thread_Left {        
        display: flex;
        flex: 0.15;
        visibility: visible;
        width: 100%;
        align-items: center;
    }

    h1 {
        font-size: 20px;
        font-weight: 700;
    }

}