.HeadingCAT {
    display: flex;
    width: 100%;
    justify-content: end;
    margin-bottom: 50px;
    min-height: 200px;
}

.HeadingCAT_Right {
    display: flex;
    color: #fff;
    align-items: center;
    justify-content: space-between;
    flex: 0.9;
    background-image: url(../media/img/AttnBackground.svg);
    padding-right: 10%;
}

.HeadingCAT_Right > div {
    align-items: center;
    margin-left: 50px;
}

@media screen and (max-width: 768px) {
    .HeadingCAT {
        display: flex;
        width: 100%;
        justify-content: end;
        margin-bottom: 50px;
        min-height: 200px;
    }

    .HeadingCAT_Right {
        display: flex;
        flex-direction: column;
        justify-content: left;
        align-items: center;
        color: #fff;
        flex: 0.84;
        background-image: url(../media/img/AttnBackground.svg);
    }

    .HeadingCAT_Right > div {
        align-items: center;
        margin-left: 30px;
        margin-bottom: 20px;
    }

    .HeadingCAT_Right > div > h1 {
        font-size: 24px;
    }

    .learnMoreButton {
        width: 100%;
        justify-content: left;
        padding-left: 15px;
    }

    .HeadingCAT_Right > button {
        visibility: hidden;
    }

    .HeadingCAT_Right > div > h1 {
        margin: 20px 0;
    }
}


/* @media screen and (max-width: 600px) {
    .HeadingCAT_Right > div > h1 {
        margin: 20px 0;
        font-size: 25px;
    }
} */