.Partners_top {
    width: 100%;
    height: 300px;
    background: url(../media/img/BG\ plus\ map.svg) no-repeat center / cover;
    margin-top: 50px;
}

.Partners_top > h1 {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    padding-top: 60px;
    font-size: 72px;
}

.Partners_down {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 80%;    
    height: 150px;
    margin: -75px auto 50px auto;
    border-radius: 15px;
    background-color: var(--prime-gm-color-lime);
    border: 2px solid var(--prime-gm-color-secondary);
}


@media screen and (max-width: 920px) {
    .Partners_top {
        height: 250px;
    }

    .Partners_down {
        display: flex;
        justify-content: space-between;
        overflow-x: scroll;
        padding-left: 20px;
    }

    .Partners_down::-webkit-scrollbar {
        display: none;
    }

    .Partners_down {
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
      }

      .Partners_top > h1 {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        padding-top: 50px;
        font-size: 55px;
    }
}
