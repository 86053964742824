.GetInTouch {
    display: flex;
    flex-direction: row;
    background: #F4FAF6;
    min-height: 450px;
    padding: 50px 0px;
}

.GetInTouch_Container {
    display: flex;
    flex-direction: row;
    width: 80%;
    margin: 0 auto;
}

.GetInTouch_Container_Forms, .GetInTouch_Container_Illustration {
    display: flex;
    width: 50%;
    justify-content: center;
}

@media screen and (max-width: 1024px) {
    .GetInTouch {
        display: flex;
        flex-direction: row;
        background: #F4FAF6;
        min-height: 450px;
        padding: 50px 0px;
    }

    .GetInTouch_Container {
        display: flex;
        flex-direction: row;
        width: 80%;
        margin: 0 auto;
    }

    .GetInTouch_Container_Forms, .GetInTouch_Container_Illustration {
        display: flex;
        width: 50%;
        justify-content: center;
    }
}

@media screen and (max-width: 768px) {
    .GetInTouch {
        display: flex;
        flex-direction: column;
        background: #F4FAF6;
        min-height: 450px;
        padding: 50px 0px;
    }

    .GetInTouch_Container {
        display: flex;
        flex-direction:column-reverse;
        width: 80%;
        margin: 0 auto;
    }

    .GetInTouch_Container_Forms, .GetInTouch_Container_Illustration {
        display: flex;
        width: 100%;
        justify-content: center;
    }

    .GetInTouch_Container_Illustration > img {
        width: 100%;
        margin-bottom: 20px;
    }
}