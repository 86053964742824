.QuotePolicies_Cards {
    display: flex;
    justify-content: space-between;
    width: 80%;
    box-sizing: border-box;
    margin: 50px auto 0px auto;
}

.QuotePolicies_Cards > div {
    width: 23%;
}

.Policy_Actions {
    display: flex;
    justify-content: space-between;
    width: 80%;
    box-sizing: border-box;
    margin: 50px auto;
}

main {
    display: flex;
    width: 80%;
    box-sizing: border-box;
    margin: 50px auto 0px auto;
}

.actions {
    display: none;
}



.hightlight {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 5px;
    border-radius: 15px;
    width: 24%;
    padding: 5px 5px;
    height: 210px;
    margin-bottom: 30px;
    background-color: #96C947;
    /* background: url(../media/img/highlight.png);  */
    background-position: center;
    background-size: cover;
    background-repeat: cover no-repeat;
}

.hightlightBar {
    display: block;
    height: 30px;
    width: 8px;
    background-color: #96C947;
}




.active-tabs {
    background: brown;    
    border-bottom: 1px solid transparent;
}

.active-tabs::before {
    content: "";
    display: block;
    position: absolute;
    top: -5px;
    left: 50%;
    transform: translateX(-50%);
    width: calc(100% + 2px);
    height: 5px;
    background: blue;
}

.content {
    display: none;
}

.active-content {
    display: block;
}

@media screen and (max-width: 1024px) { 
    .QuotePolicies_Cards {
        display: flex;
        justify-content: space-between;
        width: 80%;
        box-sizing: border-box;
        margin: 50px auto 0px auto;
    }
    
    .QuotePolicies_Cards > div {
        width: 23%;
    }

    .hightlight {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-top: 5px;
        border-radius: 15px;
        width: 24%;
        padding: 5px 5px;
        height: 210px;
        margin-bottom: 30px;
        background-color: #96C947;
        /* background: url(../media/img/highlight.png);  */
        background-position: center;
        background-size: cover;
        background-repeat: cover no-repeat;
    }
}

@media screen and (max-width: 768px) {
    .QuotePolicies_Cards{
        display: flex;
        overflow-x: scroll;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        box-sizing: border-box;
        margin: 50px auto 20px auto;
        padding: 20px 10px;
        border-bottom: 2px dashed #96C947;
        border-left: 5px solid #96C947;
        border-top: 2px dashed #96C947;
        /* border-right: 1px solid #96C947; */
    }
    
    .QuotePolicies_Cards > div {
        width: 100%;
    }

    .hightlight {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-top: 5px;
        border-radius: 15px;
        width: 24%;
        padding: 5px 0px;
        height: 210px;
        margin-bottom: 0px;
        background-color: #96C947;
        /* background: url(../media/img/highlight.png);  */
        background-position: center;
        background-size: cover;
        background-repeat: cover no-repeat;
    }
}

/* 
@media screen and (max-width: 600px) {
    .QuotePolicies_Cards{
        display: flex;
        overflow-x: scroll;
        justify-content: space-between;
        width: 80%;
        box-sizing: border-box;
        margin: 50px auto 0px auto;
    }

    .hightlight {
        display: flex;
        justify-content: center;
        padding-top: 5px;
        border-radius: 15px;
        margin: 0px 10px;
        width: 240px;
        height: 250px;
        background: url(../media/img/highlight.png);
    }
}
 */

