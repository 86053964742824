.ProductSection {
    display: flex;
    width: 100%;  
    min-height: 600px;
    margin-bottom: 50px;
}

.ProductSection_FirstRow {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 80%;  
    min-height: 400px;  
    margin: 0px auto;  
    justify-content: space-between;    
}

.ProductSection_FirstRow > div > a {
    text-decoration: none;
}

.ProductSection_FirstRow > a > div:hover {
    transform: scale(1.01);
}

.ProductSection_FirstRow > div {
    display: flex;
    width: 32%;
    margin-bottom: 20px;
    transform: scale(1);
    transition: color 0.15s ease-in-out 0.15s;
}


@media screen and (max-width: 1024px) { 
    .ProductSection_FirstRow {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 80%;  
        min-height: 400px;  
        margin: 0px auto;  
        justify-content: space-between;    
    }
    
    .ProductSection_FirstRow > div {
        display: flex;
        width: 32%;
        margin-bottom: 20px;
        transform: scale(1);
        transition: color 0.15s ease-in-out 0.15s;
    }
}

@media screen and (max-width: 768px) {
    .ProductSection_FirstRow {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        width: 80%;  
        min-height: 400px;  
        margin: 0px auto;  
        justify-content: space-between;    
    }

    .ProductSection_FirstRow > div {
        display: flex;
        width: 100%;
        margin-bottom: 20px;
        transform: scale(1);
        transition: color 0.15s ease-in-out 0.15s;
    }
}






















/* @media screen and (max-width: 768px) {
    .ProductSection_FirstRow {
        display: flex;
        flex-direction: column;
        width: 80%;      
        justify-content: center;
    }
}

.ProductSection_FirstRow_Card1 {
        display: flex;
        align-items: flex-end;
        width: 400px;
        height: 300px;
        margin-bottom: 40px;
        border-radius: 20px;
        box-shadow: 0.5em 0.5em 2em hsla(0, 0%, 0%, 0.4);
} */