.GetAQuoteMotor {
    margin: 50px 0px;
}

form {
    display: flex;
    flex-direction: column;
}

form > .GetAQuoteMotor_TopQuery {    
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 80%;
    margin: 0px auto;    
}

form > .GetAQuoteMotor_TopQuery_Tri {    
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 80%;
    margin: 0px auto 20px auto;    
}

form > .GetAQuoteMotor_MiddleQuery {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 80%;
    margin: 20px auto;
}

form > .GetAQuoteMotor_MiddleQuery > .GetAQuoteMotor_InputBox_Query {    
    display: flex;
    width: 48.5%;
    flex-direction: column;
}

.GetAQuoteMotor_InputBox_Query > input {
    display: flex;
    flex-direction: row;
    height: 40px;
    border: 1.5px solid var(--prime-gm-color-secondary);
    border-radius: 7px;
    margin-bottom: 15px;
    margin-right: 15px;
    padding: 0px 10px;
}

.GetAQuoteMotor_InputBox_Query {
    display: flex;
    width: 23%;
    flex-direction: column;
}

form > .GetAQuoteMotor_TopQuery_Tri > .GetAQuoteMotor_InputBox_Query {
    display: flex;
    width: 30%;
    flex-direction: column;
}

div > .FormSubmit {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    margin: 30px auto;
    border-radius: 8px;
}

@media screen and (max-width: 1024px) {
    .GetAQuoteMotor_InputBox_Query {
        display: flex;
        width: 23%;
        flex-direction: column;
    }

    form > .GetAQuoteMotor_TopQuery_Tri {    
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        width: 80%;
        margin: 0px auto 20px auto;    
    }

    form > .GetAQuoteMotor_TopQuery_Tri > .GetAQuoteMotor_InputBox_Query {
        display: flex;
        width: 30%;
        flex-direction: column;
    }

    form > .GetAQuoteMotor_MiddleQuery > .GetAQuoteMotor_InputBox_Query {    
        display: flex;
        width: 48.5%;
        flex-direction: column;
    }
}

@media screen and (max-width: 768px) {
    .GetAQuoteMotor_InputBox_Query {
        display: flex;
        width: 100%;
        flex-direction: column;
    }

    form > .GetAQuoteMotor_TopQuery_Tri {    
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex-wrap: nowrap;
        width: 80%;
        margin: 0px auto 20px auto;    
    }

    form > .GetAQuoteMotor_TopQuery_Tri > .GetAQuoteMotor_InputBox_Query {
        display: flex;
        width: 100%;
        flex-direction: column;
    }

    form > .GetAQuoteMotor_MiddleQuery > .GetAQuoteMotor_InputBox_Query {    
        display: flex;
        width: 48.5%;
        flex-direction: column;
    }

    .submitAction {
        width: 80%;
        margin: 0px auto;
    }
}