.Header_Main {
    /* background-image: url(../media/img/Insurancebackground1.jpg); */
    background-size: cover;
    /* background-color: #96C947; */
    background: rgb(23,92,46);
    background: linear-gradient(153deg, rgba(23,92,46,1) 30%, rgba(150,201,71,1) 100%);
}

.Header{
    display: relative;
    max-width: 100%;
    height: 90vh;
    background: url(../media/img/Header\ BG\ 1.svg) no-repeat;    
    background-size: cover;
    background-position: center center;
}

.swiper-button-next, .swiper-button-previous { 
    color: white; 
}

.Header_Section {
    display: flex;
    width: 80%;
    margin: 0 auto;
    padding-top: 50px;
    color: #fff;
}

.Header_Caption > h1 {
    font-size: 50px;
}

.Header_Caption {
    width: 50%;
    height: 400px;
}

.Header_Caption > p {
    font-size: 25px;
}

.Header_Caption > h1, .Header_Caption > p {
    margin-bottom: 40px;
}

.Header_Animation {
    width: 50%;
}

.container {
    width: 600px;
    display: flex;
    justify-content: center;
}



@media screen and (max-width: 600px) {
    .Header_Section {
        display: flex;
        flex-direction: column;
        width: 80%;
        margin: 0 auto;
        padding-top: 50px;
        color: #fff;
    }

    .Header_Caption > h1 {
        font-size: 30px;
    }

    .Header_Caption {
        width: 100%;
        height: 200px;
    }

    .Header_Animation {
        width: 100%;
    }

    .img1, .img2, .img3 {
        width: 100%;
    }
}