.ManagementItem {
    background-color: cadetblue;
    height: 350px;
    width: 23%;    
    margin-top: 20px;
    margin-right: 20px;
    /* box-shadow: 0.2em 0.2em 1em hsla(0, 0%, 0%, 0.2); */
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
}

.ManagementItem_Top {
    display: flex;
    justify-content: center;
    height: 240px;
    width: 100%;
}

.ManagementItem_Top > img {
    min-width:100%;
    min-height:100%;
}

.ManagementItem_Down {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    height: 110px;
    width: 100%;
    background-color: #ECECEC;
    color: #535353;
}

.ManagementItem_Down > p {
    font-size: 14px;
}

.ManagementItem_Down_Socials {
    margin-top: 8px;
}

.ManagementItem_Down_Socials > a {
    text-decoration: none;
    color: inherit;
}



@media screen and (max-width: 768px) {
    .ManagementItem {
        background-color: cadetblue;
        height: 350px;
        width: 100%;
        margin-right: 0px;
        /* box-shadow: 0.2em 0.2em 1em hsla(0, 0%, 0%, 0.2); */
        box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    }
}