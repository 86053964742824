.FreeQuote {
    display: block;
}

.FreeQuote_Highlight {
    display: flex;
    width: 100%;
    min-height: 70px;
    margin-bottom: 50px;
}

.FreeQuote_Highlight_Main {
    display: flex;
    flex: 0.4;
    width: 100%;
    background-color: var(--prime-gm-color-primary);
    justify-content: left;
    padding-left: 20px;
}

.FreeQuote_Highlight_Main > h1 {
    display: flex;
    align-items: center;
    color: #fff;
}

.FreeQuote_Highlight_Thread {
    display: flex;
    flex: 0.6;
    width: 100%;
    align-items: center;
}

.FreeQuote_Highlight_Thread > hr {
    display: flex;
    background-color: var(--prime-gm-color-primary);
    width: 100%;
    height: 3px;
    align-items: center;
    border: none;
}

@media screen and (max-width: 768px) {
    
    .FreeQuote_Highlight_Main > h1 {
        display: flex;
        align-items: center;
        color: #fff;
        font-size: 20px;
    }

    .FreeQuote_Highlight_Main {
        display: flex;
        flex: 0.7;
        width: 100%;
        background-color: var(--prime-gm-color-primary);
        justify-content: left;
        padding-left: 20px;
    }
}