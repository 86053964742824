.OtherBranches_Section { 
    width: 80%;   
    margin: 0px auto;
    padding: 50px 0;
}

.OtherBranches_Section > h1 {
    width: 100%;
    margin: 0px auto 30px auto;
}

.OtherBranches_Section > div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin: 0 auto;
}

.OtherBranches_Section > div > div {
    display: flex;
    width: 23%;
}

@media screen and (max-width: 1024px) {
    .OtherBranches_Section > div {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        margin: 0 auto;
    }

    .OtherBranches_Section > div > div {
        display: flex;
        width: 23%;
    }

    .OtherBranches_Section > h1 {
        width: 100%;
        margin: 0px auto 30px auto;
    }
}

@media screen and (max-width: 768px) {
    .OtherBranches_Section { 
        width: 80%;   
        margin: 0px auto;
        padding: 50px 0;
    }

    .OtherBranches_Section > div {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        margin: 0 auto;
    }

    .OtherBranches_Section > div > div {
        display: flex;
        width: 100%;
    }

    .OtherBranches_Section > h1 {
        width: 100%;
        margin: 0px auto 20px auto;
    }
}