.Forms_Highlight {
    display: flex;
    align-items: center;
    justify-content: right;
    text-align: right;
    padding: 0 10%;
    color: #fff;
    min-height: 350px;
    background: url(../media/img/formDownload.png) no-repeat;
    background-size: cover;
}

.Forms_Highlight > div > h1 {
    margin-bottom: 20px;
    color: #FEFE0C;
    font-size: 50px;
    font-weight: 600;
}

.Forms_Highlight > div > p {
    margin-bottom: 20px;
    font-size: 25px;
}

.Forms_Downloads {
    padding: 60px 0px;
}

.Forms_DownloadLinks {
    display: flex;
    justify-content: space-between;
    width: 80%;
    margin: 0px auto;
    flex-wrap: wrap;
}

@media screen and (max-width: 1024px) {

}

@media screen and (max-width: 768px) {

}

