.MainContactInfoItem_Comp{
    width: 100%;
    margin-bottom: 0px;
}

.MainContactInfoItem_Comp > div {
    margin-bottom: 20px;
}

.MainContactInfoItem_Details {
    width: 100%;
    min-height: 50px;
}

.MainContactInfoItem_highlight {
    display: flex;
    align-items: center;
    width: 100%;
    height: 50px;
    border-radius: 5px;
    background-color: var(--prime-gm-color-secondary);
}

@media screen and (max-width: 1024px) {
    .MainContactInfoItem_Comp{
        width: 100%;
        margin-bottom: 0px;
    }
    
    .MainContactInfoItem_highlight {
        display: flex;
        align-items: center;
        width: 100%;
        height: 50px;
        border-radius: 5px;
        background-color: var(--prime-gm-color-secondary);
    }
}

@media screen and (max-width: 768px) {
    .MainContactInfoItem_Comp{
        width: 100%;
        margin-bottom: 30px;
    }
    
    .MainContactInfoItem_highlight {
        display: flex;
        align-items: center;
        width: 100%;
        height: 50px;
        border-radius: 5px;
        background-color: var(--prime-gm-color-secondary);
    }
}