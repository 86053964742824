.bestProvider {
    width: 100%;
}

.bestProviderHeading {
    display: flex; 
    flex-direction: column; 
    justify-content: center; 
    width: 80%; 
    margin: 40px auto; 
    text-align: center;
}

.bestProviderColumns {
    display: flex; 
    flex-direction: row;
    justify-content: space-between; 
    width: 80%; 
    margin: 0px auto;
}

@media screen and (max-width: 1024px) { 
    .bestProviderColumns {
        display: flex; 
        flex-direction: column;
        justify-content: space-between; 
        width: 80%; 
        margin: 0px auto;
    }
}

@media screen and (max-width: 768px) {
    .bestProviderColumns {
        display: flex; 
        flex-direction: column;
        justify-content: space-between; 
        width: 80%; 
        margin: 0px auto;
    }
}