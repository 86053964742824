.Quote_Highlight {
    display: flex;
    align-items: center;
    justify-content: right;
    text-align: right;
    padding: 0 10%;
    color: #fff;
    min-height: 350px;
    background: url(../media/img/GetAQuote.png) no-repeat;
    background-size: cover;
}

.Quote_Bar > hr {
    width: 80%;
    margin: 0px auto;
    border: 8px solid var(--prime-gm-color-secondary);
    border-radius: 10px;
}

.QuotePlaceholder {
    width: 80%;
    margin: 50px auto;
    border: 8px solid var(--prime-gm-color-secondary);
    background-image: url(../media/img/insuranceBG.jpg);
    background-size: cover;
    border-radius: 10px;
    min-height: 400px;
}

.Quote_Highlight > div > h1 {
    margin-bottom: 20px;
    color: #FEFE0C;
    font-size: 50px;
    font-weight: 600;
}

.Quote_Highlight > div > p {
    margin-bottom: 20px;
    font-size: 25px;
}