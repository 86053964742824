.GetAQuoteInjury {
    margin: 50px 0px;
}

form {
    display: flex;
    flex-direction: column;
}

form > .GetAQuoteInjury_TopQuery {    
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 80%;
    margin: 0px auto;    
}

form > .GetAQuoteInjury_MiddleQuery {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 80%;
    margin: 0px auto;
}

form > .GetAQuoteInjury_MiddleQuery > .GetAQuoteInjury_InputBox_Query {    
    display: flex;
    width: 47%;
    flex-direction: column;
}

.GetAQuoteInjury_InputBox_Query > input {
    display: flex;
    flex-direction: row;
    height: 40px;
    border: 1.5px solid var(--prime-gm-color-secondary);
    border-radius: 7px;
    margin-bottom: 15px;
    margin-right: 15px;
    padding: 0px 10px;
}

.GetAQuoteInjury_InputBox_Query {
    display: flex;
    width: 24%;
    flex-direction: column;
}


@media screen and (max-width: 1024px) {

}

@media screen and (max-width: 768px) {
    .GetAQuoteInjury_InputBox_Query {
        display: flex;
        width: 100%;
        flex-direction: column;
    }

    form > .GetAQuoteInjury_TopQuery_Tri {    
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex-wrap: nowrap;
        width: 80%;
        margin: 0px auto 20px auto;    
    }

    form > .GetAQuoteInjury_TopQuery_Tri > .GetAQuoteInjury_InputBox_Query {
        display: flex;
        width: 100%;
        flex-direction: column;
    }

    form > .GetAQuoteInjury_MiddleQuery > .GetAQuoteInjury_InputBox_Query {    
        display: flex;
        width: 48.5%;
        flex-direction: column;
    }
}